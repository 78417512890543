/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
// $(document).foundation();
// $('.accordion-navigation').click(function() {
//   $(this).toggleClass('active');
//   $(this).find('.content').toggleClass('active');
// });
$( '.swipebox' ).swipebox();
$("p:has(img)").css('margin', '0'); 
$('.homepage-slider').slick({
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  lazyLoad: 'ondemand'
});
$('.testimonials-slider').slick({
  adaptiveHeight: true,
  arrows: false
});
$('.review-slider').slick({
  autoplay: true,
  autoplaySpeed: 10000,
  infinite: true,
});
$('.video-testimonials-slider').slick({
  lazyLoad: 'ondemand',
  dots: true
});
$(".reviews button").on("click", function(){
  $(".reviews .wpcf7").slideToggle(100, function(){
    $(".reviews button").css("display", "none");
  });
});
$('.games-container').slick({
    asNavFor: '.games-thumbs',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 1,
    adaptiveHeight: true
});
$('.games-thumbs').slick({
  slidesToShow: 10,
  asNavFor: '.games-container',
  focusOnSelect: true
});
$('.video-slider').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1
});
$(".testimonial-arrows .fa-chevron-left").click(function(e) {
  $('.testimonials-slider').slick('slickPrev');
});
$(".testimonial-arrows .fa-chevron-right").click(function(e) {
    $('.testimonials-slider').slick('slickNext');
});
$(".reviews-arrows .fa-chevron-circle-right").click(function(e) {
  $('.review-slider').slick('slickNext');
});
$(".reviews-arrows .fa-chevron-circle-left").click(function(e) {
  $('.review-slider').slick('slickPrev');
});


// Load iframe after page load
function init() {
var vidDefer = document.getElementsByTagName('iframe');
for (var i=0; i<vidDefer.length; i++) {
if(vidDefer[i].getAttribute('data-src')) {
vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
} } }
window.onload = init;


// Youtube Modal
 $(document).foundation();   
 var ytvideoid;
  $('.feature-modal-btn').on('click', function(e){  
    
    ytvideoid = $(this).data('ytvideoid');
    
  }); 

$(document).on('opened.fndtn.reveal', '[data-reveal]', function () {
  var modal = $(this);
  player = new YT.Player('feature-video', { //Add the player                        
                      width: '800',
                      videoId: ytvideoid, 
                      playerVars: {
                          rel            : 0,
                          theme          : 'light',
                          showinfo       : 0,
                          showsearch     : 0,
                          autoplay       : 1,
                          autohide       : 1,
                          modestbranding : 1
                      },
                      events: {
                      }
                  });
});

$(document).on('close.fndtn.reveal', '[data-reveal]', function () {  
  $('#YTMODAL .flex-video #feature-video').remove();
  $('#YTMODAL .flex-video').append('<div id="feature-video" />');
});

if($("#customerReviews").length > 0){
    $(document).ready(function(){
      $('.review-page_review').addClass('review-selected');
      $('.review-page_review').removeClass('review-selected');
    });
}

$('.off-canvas-menu-toggle').click(function(){
  $('.right-off-canvas-menu').css('display', 'block');
  $('.off-canvas-wrap').foundation('offcanvas', 'toggle', 'move-left');
});
$('.fa-times').click(function(){
  $('.off-canvas-wrap').foundation('offcanvas', 'toggle', 'move-left');
});

// $('.off-canvas-wrap').foundation('offcanvas', 'toggle', 'move-left');
$('.off-canvas-list .menu-item-has-children').append('<span class="sub-menu-arrow">▼</span>');
$('.sub-menu-arrow').click(function() {
  $(this).parent().find('.sub-menu').toggle();
});

// Disable form submit on Enter key
$('form').bind("keyup keypress", function(e) {
  var code = e.keyCode || e.which;
  if (code === 13) {
    e.preventDefault();
    return false;
  }
});

function sidebarTracking () {
  var div = document.getElementById('track');
  div.innerHTML = div.innerHTML + '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=895621920449603&ev=PageView&noscript=1"/>';
  goog_report_conversion ();
}
